import React from "react"
import { Link } from "gatsby"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Logo from "../../images/lifesync-logo-white.svg"
import { TelephoneFill } from 'react-bootstrap-icons';

import CaptionClearViews from "./captionClearViews"

const HeaderClearViews = ({ siteTitleContact }) => (
  <header className="headerClearViews">
    <Container>
      <Row className="boxedNav">
        <Col xs={6}>
          <Link to="/">
            <img
              src={Logo}
              alt="LifeSync"
              width="185"
              className="logo"
              title="LifeSync"
              loading="lazy"
            />
          </Link>
        </Col>
        <Col xs={6} className="text-right">
          <a href="tel:18003582468" className="topRightPhoneBoxed">
            <span className="mobile"><TelephoneFill size={24} /></span>
            <span className="desktop">CALL US AT 1-800-358-2468</span>
          </a>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <CaptionClearViews />
        </Col>
      </Row>
    </Container>
  </header>
)

export default HeaderClearViews
