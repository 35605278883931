import React from "react"
import scrollTo from "gatsby-plugin-smoothscroll"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"

import LayoutClearViews from "../components/Clearviews/layoutClearViews"
import SEO from "../components/seo"

import Radiolucency from "../images/radiolucency.jpg"
import Specs from "../images/specs.jpg"

import LPFormClearViews from "../components/Clearviews/lpformclearviews"

const ClearViews = () => {
  return (
    <LayoutClearViews>
      <SEO
        title="Get a Free LifeSync Radiolucent Leadwire Sample"
        description="Discover how our leadwires deliver unobstructed views and uncompromised signal and trace quality for fast accurate readings."
        ogimg={Radiolucency}
        link="https://www.lifesync.com/emgcomfort/"
      />
      <Container className="lpClearViews">
        <Row>
          <Col xs={12} md={{ order: 2, span: 5 }} lg={{ order: 2, span: 4 }}>
            <LPFormClearViews />
          </Col>
          <Col
            xs={12}
            md={{ order: 1, span: 7 }}
            lg={{ order: 1, span: 8 }}
            className="bodyContent"
            style={{ marginBottom: `0`, paddingBottom: `0` }}
          >
            <p><img src={Radiolucency} alt="Radiolucency" className="radiolucencyImg" /></p>
            <p>
              LifeSync radiolucent leadwires deliver unobstructed views and
              uncompromised signal and trace quality for fast accurate readings.
            </p>

            <p>
              Radiolucency eliminates the need to replace or reposition ECG
              leads during fluoroscopy, saving procedure time.
            </p>

            <h2>Ease, efficiency, unrivaled selection</h2>

            <p>
              Single-use or reusable. Shielded or unshielded. The largest
              radiolucent leadwire portfolio all from one source.
            </p>

            <p>
              Our high-quality solutions enhance productivity, enable
              standardization and support outstanding, cost-effective patient
              care.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="bodyContent" style={{ marginBottom: `0`, paddingBottom: `0` }}>
            <p><img src={Specs} alt="Specs" className="specsImg" /></p>
            <p className="compatible">Compatible with DIN, GE, CMS Twin-Pin, ConMed D Series and more.<br /><br /></p>
            <p><b>To request a free sample*, fill out this form or contact<br />
            <a href="mailto:customerservice@lifesync.com">customerservice@lifesync.com</a></b><br />
            <span className="smaller">* Free samples are a limited-time offer.<br />
            * The images shown are for illustration purposes only.</span></p>
          </Col>
        </Row>
      </Container>
    </LayoutClearViews>
  )
}

export default ClearViews
