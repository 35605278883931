import React from "react"
import * as Yup from "yup"
import * as axiosjs from "axios"
import Button from "react-bootstrap/Button"
import { useForm } from "react-hook-form"
// import { navigate } from "gatsby"
import { Link } from "gatsby"
import Cookies from "js-cookie";
import $ from "jquery";

const validateEmail = async value => {
  console.log(value);
  if (!value) {
    return false
  } else {
    const valid = await emailSchema.isValid({ email: value })
    console.log(valid);
    if (valid) {
      const validate = await axiosjs.post(
        "https://lifesync.meritmile.com/api/email-validate",
        {
          email: value,
        }
      )
      return validate.data.deliverable
    } else {
      return false
    }
  }
}

const emailSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
})

const onSubmit = async data => {
  let formObject = $('#contactForm');
  let postDt = new Date();
  let loadDt = new Date();
  //let _sid = 'qq3eu25egawyabolsjpyzcr5';
  let _sid = Cookies.get('ASP.NET_SessionId');
  if (formObject.find('input[name="js"]').length === 0) {
      let str = '<input type="hidden" name="js" value="1" />';
      formObject.append(str);
  }
  if (formObject.find('input[name="ttp"]').length === 0) {
      let str = '<input type="hidden" name="ttp" value="' + ((postDt - loadDt) / 1000).toString() + '" />';
      formObject.append(str);
  }
  if (formObject.find('input[name="sid"]').length === 0) {
      let str = '<input type="hidden" name="sid" value="' + _sid + '" />';
      formObject.append(str);
  } else {
      formObject.find('input[name="sid"]').val(_sid);
  }

  let post_data = formObject.serialize();

  console.log(post_data);

  $.ajax({
    url: "https://metrix.meritmile.com/email.aspx?ajax=1",
    type: 'POST',
    data: post_data,
    cache: false,
    dataType: 'json',
    success: function(data) {
        // Success..
        let r = data[0];
        let redirect_url = "https://www.lifesync.com/thank-you-clearviews/";
        window.location.href = redirect_url;
    },
    // Fail..
    error: function(result) {
      let r = result[0];

        formObject.find('.feedback').show().addClass('alert-error').html(r.Message).css('display', 'block').focus();
        formObject.find('.submit').attr('disabled', false).removeClass('disabled');
    }
  });

  /* console.log('on submit')

  const axios = axiosjs.create({
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
  await axios.post("https://metrix.meritmile.com/email.aspx?ajax=1", data)

  navigate("/thank-you/") */

}

const LPFormClearViews = () => {
  const { register, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur'
  });
  return (
    <div id="freeSampleForm" className="form-wrapper">
      <form onSubmit={handleSubmit(onSubmit)} id="contactForm">
        <div className="form-group">
          <h3>
            Request your free LifeSync<br />
            radiolucent leadwire sample.
          </h3>
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="typeOfLab">* Type of Lab</label>
          <select
            name="typeOfLab"
            className="form-control"
            ref={register({ required: true })}
          >
            <option value="">Choose One...</option>
            <option value="Cath Lab">
              Cath Lab
            </option>
            <option value="EP Lab">
              EP Lab
            </option>
          </select>
          {errors.typeOfLab && <div className="error-message">This field is required</div>}
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="deviceType">* Device Type</label>
          <select
            name="deviceType"
            className="form-control"
            ref={register({ required: true })}
          >
            <option value="">Choose One...</option>
            <option value="GE">
              GE
            </option>
            <option value="Philips CMS">
              Philips CMS
            </option>
            <option value="Philips IntelliVue">
              Philips IntelliVue
            </option>
            <option value="CONMEDM">
              CONMED
            </option>
            <option value="DIN - Style">
              DIN - Style
            </option>
          </select>
          {errors.deviceType && <div className="error-message">This field is required</div>}
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="firstname">* First Name</label>
          <input
            type="text"
            name="firstname"
            placeholder=""
            className="form-control"
            ref={register({ required: true, minLength: 2, maxLength: 100 })}
          />
          {errors.firstname && <div className="error-message">This field is required</div>}
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="lastname">* Last Name</label>
          <input
            type="text"
            name="lastname"
            placeholder=""
            className="form-control"
            ref={register({ required: true, minLength: 2, maxLength: 100 })}
          />
          {errors.lastname && <div className="error-message">This field is required</div>}
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="email">* Email</label>
          <input
            type="email"
            name="email"
            placeholder=""
            ref={register({ required: true, validate: validateEmail})}
            className="form-control"
          />
          {errors.email && <div className="error-message">A valid email is required</div>}
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="phone">* Phone</label>
          <input
            type="text"
            name="phone"
            placeholder=""
            className="form-control"
            ref={register({ required: true })}
          />
          {errors.phone && <div className="error-message">This field is required</div>}
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="hospitalName">* Hospital Name</label>
          <input
            type="text"
            name="hospitalName"
            placeholder=""
            className="form-control"
            ref={register({ required: true, minLength: 2, maxLength: 160 })}
          />
          {errors.hospitalName && <div className="error-message">This field is required</div>}
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="address1">* Hospital Address</label>
          <input
            type="text"
            name="address1"
            placeholder=""
            className="form-control"
            ref={register({ required: true, minLength: 2, maxLength: 100 })}
          />
          {errors.address1 && <div className="error-message">This field is required</div>}
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="address2">Hospital Address 2</label>
          <input
            type="text"
            name="address2"
            placeholder=""
            className="form-control"
          />
        </div>
        <div className="row">
          <div className="col-6 padding-r-0">
            <div className="form-group">
              <label className="form-label" htmlFor="city">* City</label>
              <input
                type="text"
                name="city"
                placeholder=""
                className="form-control"
                ref={register({ required: true, minLength: 2, maxLength: 100 })}
              />
              {errors.city && <div className="error-message">This field is required</div>}
            </div>
          </div>
          <div className="col-6 padding-l-0">
            <div className="form-group">
              <label className="form-label" htmlFor="statee">* State</label>
              <select
                name="state"
                className="form-control"
                ref={register({ required: true })}
              >
                <option value="">Choose One...</option>
                <option value="Alabama">Alabama</option>
                <option value="Alaska">Alaska</option>
                <option value="Arizona">Arizona</option>
                <option value="Arkansas">Arkansas</option>
                <option value="California">California</option>
                <option value="Colorado">Colorado</option>
                <option value="Connecticut">Connecticut</option>
                <option value="Delaware">Delaware</option>
                <option value="District Of Columbia">
                  District Of Columbia
                </option>
                <option value="Florida">Florida</option>
                <option value="Georgia">Georgia</option>
                <option value="Hawaii">Hawaii</option>
                <option value="Idaho">Idaho</option>
                <option value="Indiana">Indiana</option>
                <option value="Iowa">Iowa</option>
                <option value="Kansas">Kansas</option>
                <option value="Kentucky">Kentucky</option>
                <option value="Louisiana">Louisiana</option>
                <option value="Maine">Maine</option>
                <option value="Maryland">Maryland</option>
                <option value="Massachusetts">Massachusetts</option>
                <option value="Michigan">Michigan</option>
                <option value="Minnesota">Minnesota</option>
                <option value="Mississippi">Mississippi</option>
                <option value="Missouri">Missouri</option>
                <option value="Montana">Montana</option>
                <option value="Nebraska">Nebraska</option>
                <option value="Nevada">Nevada</option>
                <option value="New Hampshire">New Hampshire</option>
                <option value="New Jersey">New Jersey</option>
                <option value="New Mexico">New Mexico</option>
                <option value="New York">New York</option>
                <option value="North Carolina">North Carolina</option>
                <option value="North Dakot">North Dakota</option>
                <option value="Ohio">Ohio</option>
                <option value="Oklahoma">Oklahoma</option>
                <option value="Oregon">Oregon</option>
                <option value="Pennsylvania">Pennsylvania</option>
                <option value="Rhode Island">Rhode Island</option>
                <option value="South Carolina">South Carolina</option>
                <option value="South Dakota">South Dakota</option>
                <option value="Tennessee">Tennessee</option>
                <option value="Texas">Texas</option>
                <option value="Utah">Utah</option>
                <option value="Vermont">Vermont</option>
                <option value="Virginia">Virginia</option>
                <option value="Washington">Washington</option>
                <option value="West Virginia">West Virginia</option>
                <option value="Wisconsin">Wisconsin</option>
                <option value="Wyoming">Wyoming</option>
              </select>
              {errors.state && <div className="error-message">This field is required</div>}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6 padding-r-0">
            <div className="form-group">
              <label className="form-label" htmlFor="postalcode">* Postal Code</label>
              <input
                type="text"
                name="postalcode"
                placeholder=""
                className="form-control"
                ref={register({ required: true, minLength: 4, maxLength: 15 })}
              />
              {errors.postalcode && <div className="error-message">This field is required</div>}
            </div>
          </div>
          <div className="col-6 padding-l-0">
            <div className="form-group">
              <label className="form-label" htmlFor="countrycode">Country (US only)</label>
              <select
                as="select"
                name="countrycode"
                className="form-control"
                ref={register({ required: true })}
              >
                <option value="US">United States</option>
              </select>
              {errors.countrycode && <div className="error-message">This field is required</div>}
            </div>
          </div>
        </div>
        <div className="form-group">
            <input type="hidden" name="referrer" value="https://www.lifesync.com/clearviews/" />
            <input type="hidden" name="auth" value="57745768-1f2d-4ef4-8999-449a5e0a9a1d" />
            <input type="text" className="hu" id="human-check" name="human-check" />
            <div className="feedback"></div>
            <div className="fix"></div>
        </div>
        <Button variant="primary" type="submit" fullWidth={true} type="Submit">
          Request Your Sample
        </Button>
        <p className="smaller">
          By submitting this form, you agree to the <Link to="/privacy-policy/">Terms of Service and Privacy Policy</Link>.
        </p>
      </form>
    </div>
  )
}

export default LPFormClearViews
